import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';

@Component({
  selector: 'app-permission-not-found',
  templateUrl: './permission-not-found.component.html'
})
export class PermissionNotFoundComponent implements OnInit {

  constructor(public globals: Globals) { }

  ngOnInit(): void {
  }

}
