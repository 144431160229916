import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
declare var $, Swal: any;
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-intermediate-page',
  templateUrl: './intermediate-page.component.html',
  styleUrls: ['./intermediate-page.component.css']
})
export class IntermediatePageComponent implements OnInit {

  constructor(public globals: Globals, private router: Router, private AuthService: AuthService,
    private route: ActivatedRoute, private CookieService: CookieService) { }

  ngOnInit(): void {
    this.globals.isLoading = true;

    // let token = (this.route.snapshot.paramMap.get('token')) ? this.route.snapshot.paramMap.get('token') : null;
    let token = this.route.snapshot.queryParams.IfmaToken
      ? this.route.snapshot.queryParams.IfmaToken
      : null;
    //let product = (this.route.snapshot.paramMap.get('product')) ? this.route.snapshot.paramMap.get('product') : null;
    this.CookieService.set('amsn_token', token, 365, '/', this.globals.CookieDomainUrl);

    this.AuthService.checkAMSNLogin({ 'usertoken': token, 'ip_address': null, 'user_agent': null })
      .then((data) => {
        this.AuthService.getAuthUser()
          .then((data) => {
            // if(product!=null){        
            //   this.router.navigate(['/landing-dashboard/'+product]);
            // } else {

            // alert("hi")
            this.router.navigate(['/landing-dashboard']);
            // }
          }, (error) => {
            this.globals.isLoading = false;
            this.globals.errorSweetAlert();
          });
      },
        (error) => {
          this.globals.isLoading = false;
          if (error.status == 400) {
            this.globals.sweetAlert('warning', 'Deactive', 'Your account is deactive. so you cannot login.');
          } else if (error.status == 401) {
            this.globals.sweetAlert('warning', 'Invalid Login Credentials', 'Either email or password is incorrect.');
          } else {
            this.globals.errorSweetAlert();
          }
        });
  }

}
