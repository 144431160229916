
<div class="inner_content_block">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-xl-12 col-lg-12 col-md-12">   
        <div class="notfound_block">
          <div class="row">
            <div class="col-12 col-xl-8 col-lg-8 col-md-12">
              <div class="not_found_title_block">
                <img src="{{globals.cndURL}}assets/images/amsn-auth.jpg" alt="" class="img-fluid" />
              <h1 class="float-start">Permission Not Found</h1>
              <div class="clearfix"></div>
            </div>
            <div class="not_found_content">
              <p class="padding_15">We're sorry, but the page you're looking for couldn't be found. The information may have been moved or deleted.</p>
              <p class="padding_15">You may also return to the <a routerLink="/dashboard">Dashboard</a>.</p>
            </div>
            </div>
            <div class="col-12 col-xl-4 col-lg-4 col-md-12">
              <div class="contact_block">
                <p>Please contact us with any questions you may have about IFMA.</p>
                <a routerLink="" class="all_btn themewhite_btn">Contact Us</a>
              </div>
            </div>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</div>