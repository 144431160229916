<div class="my_products_block">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-xl-12 col-lg-12 col-md-12">
                <div class="products_menu"><span class="product_text">My Products <i
                            class="fa fa-caret-down ms-1"></i></span>
                    <div class="dropdown-menu" kendoTooltip position="left">
                        <!-- <div class="product_half">
                            <h4>As an Individual</h4>
                            <ul>
                                <li>
                                    <a href="{{globals.covidUrl}}"><img src="{{globals.cndURL}}assets/images/covid.png"
                                            alt="" class="img-fluid" /><span>COVID</span>
                                    </a>
                                    <div class="clearfix"></div>
                                </li>
                                <li *ngIf="dcm_present">
                                    <a (click)="viewIndividualDCM()"><img src="{{globals.cndURL}}assets/images/dcm.png"
                                            alt="" class="img-fluid" /><span>Self Assessment Tool</span>
                                    </a>
                                    <div class="clearfix"></div>
                                </li>
                            </ul>
                        </div> -->
                        <!-- <button class="buy_lic_btn" kendoButton><a href="https://www.amsn.org/iclp"
                                >Buy Licenses</a></button> -->
                        <div class="product_half">
                            <h4>As an Organization</h4>
                            <ul>
                                <li *ngIf="connector_present">
                                    <a href="{{globals.connectorUrl}}"><img
                                            src="{{globals.cndURL}}assets/images/connector.png" alt=""
                                            class="img-fluid" /><span>Connector</span>
                                    </a>
                                    <div class="clearfix"></div>
                                </li>
                                <li *ngIf="!connector_present">
                                    <a (click)="registerOrg()"><img src="{{globals.cndURL}}assets/images/connector.png"
                                            alt="" class="img-fluid" /><span>Register your Organization</span>
                                    </a>
                                    <div class="clearfix"></div>
                                </li>
                                <!-- <li *ngIf="org_dcm_present">
                                    <a (click)="viewOrgDCM()"><img src="{{globals.cndURL}}assets/images/dcm.png" alt=""
                                            class="img-fluid" /><span>Self Assessment Tool</span>
                                    </a>
                                    <div class="clearfix"></div>
                                </li> -->
                                <!-- <button class="buy_lic_btn" kendoButton><a href="https://www.amsn.org/iclp"
                                        >Buy Licenses</a></button> -->
                                <li class="active" *ngIf="administrator_present">
                                    <img src="{{globals.cndURL}}assets/images/administrator.png" alt=""
                                        class="img-fluid" /><span>Adminstration</span>
                                    <div class="clearfix"></div>
                                </li>
                            </ul>
                        </div>
                        <div class="clearfix"></div>
                        <!-- <div class="links_block"> <a href="https://faqs.ifma.uatbyopeneyes.com/home" target="_blank" class="feedback_a" >FAQs</a>
                            <a href="#" class="feedback_a" >Share a Feedback</a></div> -->
                        <!-- <ng-container *ngIf="!dcm_present || !connector_present">
                            <hr class="mt-0" />
                            <h4>Might be Interested in</h4>
                            <ul>
                                <li *ngIf="!connector_present">
                                    <a href="{{globals.connectorUrl}}" target="_blank"><img
                                            src="{{globals.cndURL}}assets/images/connector.png" alt=""
                                            class="img-fluid" /><span>Connector</span>
                                    </a>
                                    <div class="clearfix"></div>
                                </li>
                                <li *ngIf="!dcm_present">
                                    <a href="https://www.amsn.org/oclp"><img
                                            src="{{globals.cndURL}}assets/images/dcm.png" alt=""
                                            class="img-fluid" /><span>Self Assessment Tool</span></a>
                                    <div class="clearfix"></div>
                                </li>
                            </ul>
                        </ng-container> -->
                        <!-- <div *ngIf="!covid_present || !dcm_present || !connector_present" class="other_product_text">To buy new product <a href="#">Click here</a>.</div> -->
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="breadcrumb_block"
    *ngIf="globals.ProductId!=globals.ProductIdArray[1] && globals.institute_details && ((globals.institute_details.role_value == 2 || globals.institute_details.role_value == 1) || ((globals.institute_details.role_value != 2 && globals.institute_details.role_value != 1)))">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-xl-12 col-lg-12 col-md-12">
                <!-- <ol class="breadcrumb">
                    <li class="breadcrumb-item" [ngClass]="{'active':globals.breadcrumbs[1]==''}">
                        {{globals.breadcrumbs[0]}}
                    </li>
                    <li class="breadcrumb-item" *ngIf="globals.breadcrumbs[1]!=''" [ngClass]="{'active':globals.breadcrumbs[2]==''}">
                        {{globals.breadcrumbs[1]}}
                    </li>
                    <li class="breadcrumb-item" *ngIf="globals.breadcrumbs[2]!=''" [ngClass]="{'active':globals.breadcrumbs[3]!=''}">
                        {{globals.breadcrumbs[2]}}
                    </li>
                </ol> -->

                <div class="admin_institute_block"
                    *ngIf="globals.ProductId!=globals.ProductIdArray[1] && globals.institute_details && (globals.institute_details.role_value == 2 || globals.institute_details.role_value == 1)">
                    <ng-container *ngIf="globals.selected_institute!=null">
                        <div class="current_org me-3">
                            <b>Selected Organization:</b><span *ngIf="globals.selected_institute.provider_id"
                                class="id_box mb-0 me-2">{{globals.selected_institute.provider_id}}</span>
                            <span class="org_name">{{globals.selected_institute.institute_name}} </span>
                            <!-- <span
                                *ngIf="!selected_institute.primary_contact_id || !selected_institute.fein_number || selected_institute.institute_types == '' || selected_institute.institute_owners == ''"
                                kendoTooltip title="Missing information" class="icon_btn information_icon red me-2 ms-2"
                                position="bottom"><i class="fa fa-exclamation-circle"></i></span> -->
                            <button kendoButton kendoTooltip title="Clear Selected Organization"
                                (click)="clearSelectedOrganization()" class="icon_btn red me-0 ms-0"
                                position="bottom"><i class="fa fa-times"></i></button>
                        </div>
                    </ng-container>
                    <!-- <a *ngIf="globals.selected_institute==null" class="switch_button"
                    (click)="globals.dialogOpened = true;"><i class="fa fa-exchange" aria-hidden="true"></i> Select
                    Organization</a>
                <a *ngIf="globals.selected_institute!=null" class="switch_button"
                    (click)="globals.dialogOpened = true;"><i class="fa fa-exchange" aria-hidden="true"></i> Switch
                    Organization</a> -->       
                    <a  class="switch_button" (click)="select_view()"><i class="fa fa-check-square-o"
                            aria-hidden="true"></i> Select Organization</a>
                </div>

                <div class="admin_institute_block"
                    *ngIf="globals.ProductId!=globals.ProductIdArray[1] && globals.institute_details && (globals.institute_details.role_value != 2 && globals.institute_details.role_value != 1)">
                    <ng-container *ngIf="globals.institute_details.institute!=null">

                        <div class="current_org me-3" [ngClass]="{'noborder_org': !(instituteList.length>2)}">
                            <b>Selected Organization:</b><span *ngIf="globals.institute_details.institute.provider_id"
                                class="id_box mb-0 me-2">{{globals.institute_details.institute.provider_id}}</span>
                            <span class="org_name">{{globals.institute_details.institute.institute_name}} </span>
                            <!-- <span
                                *ngIf="!selected_institute.primary_contact_id || !selected_institute.fein_number || selected_institute.institute_types == '' || selected_institute.institute_owners == ''"
                                kendoTooltip title="Missing information" class="icon_btn information_icon red me-2 ms-2"
                                position="bottom"><i class="fa fa-exclamation-circle"></i></span> -->
                        </div>
                    </ng-container>
                    <!-- <a class="switch_button" (click)="globals.dialogOpened = true;"><i class="fa fa-exchange"
                        aria-hidden="true"></i> Switch Organization</a> -->
                    <a class="switch_button" *ngIf="instituteList.length>2" (click)="select_view()"><i
                            class="fa fa-check-square-o" aria-hidden="true"></i> Select Organization</a>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="clearfix"></div>
<div class="switch_view_block">
    <h5 class="float-start">Select Organization</h5>
    <button class="float-end close" (click)="select_view_close()" type="button"><span>×</span></button>
    <div class="clearfix"></div>

    <p class="padding_15" *ngIf="globals.institute_details.role_value!=1 && globals.institute_details.role_value!=2">
        <b>Current View:</b>
        <ng-container *ngIf="globals.institute_details.role_value==1">OESS Administrator</ng-container>
        <ng-container *ngIf="globals.institute_details.role_value==2">IFMA Administrator</ng-container>
        <ng-container *ngIf="globals.institute_details.role_value==5">Individual User</ng-container>
        <ng-container
            *ngIf="globals.institute_details.role_value!=1 && globals.institute_details.role_value!=2 && globals.institute_details.role_value!=5">
            ORGANIZATIONS</ng-container>
    </p>
    <ng-container *ngIf="OESS_Admin_present && globals.institute_details.role_value!=1">
        <p class="padding_15">
            <button class="view_btn" (click)="switchRole(1)">View as an OESS Administrator</button>
        </p>
    </ng-container>
    <ng-container *ngIf="AMSN_Admin_present && globals.institute_details.role_value!=2">
        <p class="padding_15">
            <button class="view_btn" (click)="switchRole(2)">View as an IFMA Administrator</button>
        </p>
    </ng-container>
    <!-- <ng-container *ngIf="Individual_present && globals.institute_details.role_value!=5">
        <p class="padding_15">
            <button class="view_btn" (click)="switchRole(5)">View as an Individual User</button>        
        </p>
    </ng-container>  -->

    <!-- <p *ngIf="Recently_instituteList.length==0">To select the organization <button class="view_btn"
            (click)="globals.dialogOpened = true;">Click here</button></p>

    <p *ngIf="Recently_instituteList.length>0">To select the different organization <button class="view_btn"
            (click)="globals.dialogOpened = true;">Click here</button></p> -->
    <div class="clearfix mb-2"></div>
    <button class="all_btn theme_btn float-end" *ngIf="Recently_instituteList.length==0"
        (click)="globals.dialogOpened = true;">Select Organization</button>

    <button class="all_btn theme_btn float-end" *ngIf="Recently_instituteList.length>0"
        (click)="globals.dialogOpened = true;">Switch Organization</button>

    <p class="mb-2" *ngIf="globals.institute_details.role_value==1 || globals.institute_details.role_value==2">
        <b>Recently accessed Organizations</b>
    </p>
    <p class="mb-2" *ngIf="globals.institute_details.role_value!=1 && globals.institute_details.role_value!=2"><b>Your
            Organizations</b></p>
    <div class="clearfix mb-2"></div>
    <div class="filter_block mb-3" *ngIf="Recently_instituteList.length > 0">
        <!-- <ul>
            <li><i class="fa fa-list-ul"></i>Type</li>
            <li><i class="fa fa-user"></i>Ownership</li>
        </ul> -->
    </div>
    <ng-container *ngFor="let institute of Recently_instituteList; let i=index;">
        <div class="border_line">
            <div class="institute_block system_block pb-0">
                <div class="content_block">
                    <div class="id_box float-start me-3" *ngIf="institute.provider_id!=null">
                        {{institute.provider_id}}</div>
                    <h5 class="float-start">{{institute.institute_name}}</h5>
                    <div class="clearfix"></div>
                    <div class="address_phone">

                        <!-- <p class="system_name" *ngIf="institute.institute_systems">
                            <b>System:</b>{{institute.institute_systems.system}}
                        </p>
                        <p class="system_name" *ngIf="!institute.institute_systems">
                            <b>System:</b>Not Affiliated
                        </p> -->

                        <p><i class="fa fa-map-marker"></i>
                            <span>{{institute.address1}}<br>{{institute.city}},
                                {{institute.state_abbreviation}} {{institute.zipcode}}
                            </span>
                        </p>
                        <p><i class="fa fa-phone"></i>
                            <span *ngIf="institute.phone_number">{{institute.phone_number |
                                phone}}</span>
                            <span *ngIf="!institute.phone_number">NA</span>
                        </p>

                        <!-- <p>
                            <i class="fa fa-list-ul"></i>
                            <span
                                *ngIf="institute.institute_types && institute.institute_types.display_text">{{institute.institute_types.display_text}}</span>
                            <span
                                *ngIf="!(institute.institute_types && institute.institute_types.display_text)">NA</span>
                        </p>
                        <p><i class="fa fa-user"></i>
                            <span
                                *ngIf="institute.institute_owners && institute.institute_owners.display_text">{{institute.institute_owners.display_text}}</span>
                            <span
                                *ngIf="!(institute.institute_owners && institute.institute_owners.display_text)">NA</span>
                        </p> -->
                    </div>
                    <div class="active_user_block">Divisions<b>{{institute.total_divisions}}</b>
                    </div>
                    <!-- <div class="active_user_block">Units<b>{{institute.total_units}}</b>
                    </div> -->
                    <div class="active_user_block">Users<b>{{institute.users}}</b>
                    </div>
                    <div class="active_user_block">Cohort<b>{{institute.cohort_users}}</b>
                    </div>
                    <!-- <div class="active_user_block mb-5">Licenses<b>{{institute.available_licenses}}</b>
                    </div> -->
                    <ng-template
                        [ngIf]="globals.selected_institute!=null && globals.selected_institute!='' && institute.id==globals.selected_institute.id"
                        [ngIfElse]="noSelectedFound">
                        <div class="selected_btn"><span>Currently Selected</span></div>
                    </ng-template>
                    <ng-template #noSelectedFound>
                        <div class="selected_btn"><button class="all_btn theme_btn"
                                (click)="switchOrg(institute)">Select</button></div>
                    </ng-template>
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </ng-container>


</div>


<kendo-dialog [minWidth]="550" [width]="550" *ngIf="globals.dialogOpened" (close)="close();">
    <kendo-dialog-titlebar>
        <h5 *ngIf="globals.selected_institute==null" class="modal-title" id="switch_org_modalLabel">Select
            Organization</h5>
        <h5 *ngIf="globals.selected_institute!=null" class="modal-title" id="switch_org_modalLabel">Switch
            Organization</h5>
    </kendo-dialog-titlebar>
    <div kendoDialogContainer>

        <p class="padding_15">Below is the list of organizations that you are affiliated with. you can select/switch an
            organization from the list below.</p>

        <kendo-formfield [ngClass]="{'k-form-field-error': orgError}">
            <label><span>*</span> Organization</label>
            <kendo-dropdownlist id="institute_id" [(ngModel)]="institute_id" [data]="instituteList"
                [textField]="'institute_name'" [valueField]="'id'" [valuePrimitive]="true" [filterable]="true"
                (filterChange)="instituteFilter($event)" (selectionChange)="filterData($event)"
                [virtual]="globals.virtual">
                <ng-template kendoDropDownListItemTemplate let-dataItem>
                    <span title="{{ dataItem.institute_name }}">{{ dataItem.institute_name
                        }}</span>
                </ng-template>
                <ng-template kendoMultiSelectNoDataTemplate>
                    <h6>NO ORGANIZATION FOUND</h6>
                </ng-template>
            </kendo-dropdownlist>
            <div class="k-form-error" *ngIf="orgError">
                Please select organization.
            </div>
        </kendo-formfield>

        <p class="mb-2" *ngIf="selected_institute!=null"><b>Selected Organization</b></p>
        <div class="filter_block mt-2 mb-3" *ngIf="selected_institute!=null">
            <!-- <ul>
                <li><i class="fa fa-list-ul"></i>Type</li>
                <li><i class="fa fa-user"></i>Ownership</li>
            </ul> -->
        </div>
        <div class="border_line" *ngIf="selected_institute!=null">
            <div class="institute_block pb-0">
                <div class="content_block">
                    <div class="address_phone">
                        <div class="id_box float-start me-3" *ngIf="selected_institute.provider_id!=null">
                            {{selected_institute.provider_id}}</div>
                        <h5 class="float-start">{{selected_institute.institute_name}}</h5>
                        <div class="clearfix"></div>
                        <!-- <p class="system_name" *ngIf="selected_institute.institute_systems">
                            <b>System:</b>{{selected_institute.institute_systems.system}}
                        </p>
                        <p class="system_name" *ngIf="!selected_institute.institute_systems">
                            <b>System:</b>Not Affiliated
                        </p> -->
                        <p><i class="fa fa-map-marker"></i>
                            <span>{{selected_institute.address1}}<br>{{selected_institute.city}},
                                <!-- <ng-container *ngIf="institute.county!=null && institute.county!=''">, {{institute.county}}</ng-container> -->
                                {{selected_institute.state_abbreviation}} {{selected_institute.zipcode}}
                            </span>
                        </p>
                        <p><i class="fa fa-phone"></i>
                            <span *ngIf="selected_institute.phone_number">{{selected_institute.phone_number |
                                phone}}</span>
                            <span *ngIf="!selected_institute.phone_number">NA</span>
                        </p>
                    </div>
                    <!-- <div class="active_user_block">
                        Divisions<b>{{selected_institute.total_divisions}}</b></div> -->
                    <!-- <div class="active_user_block">Units<b>{{selected_institute.total_units}}</b>
                    </div> -->
                    <div class="active_user_block">Divisions<b>{{selected_institute.total_divisions}}</b>
                    </div>
                    <div class="active_user_block">Users<b>{{selected_institute.users}}</b>
                    </div>
                    <div class="active_user_block">Cohorts<b>{{selected_institute.cohort_users}}</b>
                    </div>
                    <!-- <div class="active_user_block">Licenses<b>{{selected_institute.available_licenses}}</b>
                    </div> -->
                    <!-- <p> -->
                        <!-- <i class="fa fa-list-ul"></i>
                        <span
                            *ngIf="selected_institute.institute_types && selected_institute.institute_types.display_text">{{selected_institute.institute_types.display_text}}</span>
                        <span
                            *ngIf="!(selected_institute.institute_types && selected_institute.institute_types.display_text)">NA</span> -->
                        <!-- <span
                            class="no_border d-none d-xxl-block"><i class="fa fa-user"></i>
                            <span class="no_border"
                                *ngIf="selected_institute.institute_owners && selected_institute.institute_owners.display_text">{{selected_institute.institute_owners.display_text}}</span>
                            <span class="no_border" *ngIf="!(selected_institute.institute_owners && selected_institute.institute_owners.display_text)">NA</span>
                        </span> -->
                    <!-- </p> -->
                    <!-- <p><i class="fa fa-user"></i>
                        <span
                            *ngIf="selected_institute.institute_owners && selected_institute.institute_owners.display_text">{{selected_institute.institute_owners.display_text}}</span>
                        <span
                            *ngIf="!(selected_institute.institute_owners && selected_institute.institute_owners.display_text)">NA</span>
                    </p> -->
                    <div class="clearfix"></div>
                </div>
            </div>
        </div>

        <div class="footer_button text-end">
            <button *ngIf="globals.selected_institute==null" type="button" class="all_btn theme_btn"
                (click)="selectOrg()" [disabled]="btnDisabled">Select</button>
            <button *ngIf="globals.selected_institute!=null" type="button" class="all_btn theme_btn"
                (click)="selectOrg()" [disabled]="btnDisabled">Switch</button>
        </div>

    </div>
    <!-- <kendo-dialog-actions>
                <button kendoButton (click)="close('no')">No</button>
                <button kendoButton (click)="close('yes')" primary="true">Yes</button>
            </kendo-dialog-actions> -->
</kendo-dialog>