<div class="inner_content_block">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-xl-12 col-lg-12 col-md-12">
                <div class="title_block">
                    <h1 class="float-start">
                        <ng-template [ngIf]="id==0" [ngIfElse]="updateTitle"> Add a Subdomain
                        </ng-template>
                        <ng-template #updateTitle> Update a Subdomain </ng-template>
                    </h1>
                    <a kendoButton routerLink="/{{this.globals.currentLink.split('/')[1]}}/configuration/subdomain/list"
                        class="all_btn theme_btn float-end" *ngIf="globals.permissions['subdomain-list']">List
                        of
                        Subdomains</a>
                    <a kendoButton
                        routerLink="/{{this.globals.currentLink.split('/')[1]}}/configuration/subdomain/interpretation/{{id}}"
                        class="all_btn theme_btn float-end"
                        *ngIf="id != 0 && globals.currentModule == 'dcm' && globals.permissions['edit-subdomain']">Interpretation</a>
                    <div class=" clearfix">
                    </div>
                </div>
                <p *ngIf="id != 0" class="padding_15">Please be mindful that the change will be reflected immediately to
                    all past,
                    present and future assessments.</p>
                <form class="k-form" [formGroup]="subdomainForm">
                    <fieldset class="k-form-fieldset">
                        <div class="grey_form_block">
                            <!-- <h4 *ngIf="id != 0">Subdomain ID: <span
                                    class="id_box mb-0">{{subdomainForm.controls.subdom_provider_id.value}}</span></h4> -->
                            <div class="row">
                                <div class="col-12 col-xl-6 col-lg-6 col-md-6">
                                    <kendo-formfield>
                                        <label><span>*</span> Domain</label>
                                        <kendo-dropdownlist #domain_id formControlName="domain_id" [data]="domainList"
                                            [textField]="'name'" [valueField]="'id'" [valuePrimitive]="true"
                                            [filterable]="true" (filterChange)="domainFilter($event)">
                                            <ng-template kendoMultiSelectNoDataTemplate>
                                                <h6>NO DOMAIN FOUND</h6>
                                            </ng-template>
                                        </kendo-dropdownlist>
                                        <kendo-formerror *ngIf="subdomainForm.controls.domain_id.errors?.required">
                                            Please select domain.
                                        </kendo-formerror>
                                    </kendo-formfield>
                                </div>
                                <div class="col-12 col-xl-6 col-lg-6 col-md-6">
                                    <kendo-formfield [ngClass]="{'k-form-field-error': duplicate_name}"
                                        class="error_height">
                                        <label><span>*</span> Subdomain Name</label>
                                        <input formControlName="name" #name maxlength="200" kendoTextBox
                                            (keyup)="duplicate_name = false" />
                                        <kendo-formerror *ngIf="subdomainForm.controls.name.errors?.required">
                                            Please enter subdomain name.
                                        </kendo-formerror>
                                        <kendo-formerror *ngIf="subdomainForm.controls.name.errors?.pattern">
                                            Please enter valid subdomain name. Allowed special characters are dash(-),
                                            underscore(_), round brackets, at the rate(&#64;), exclamation(!), hash(#),
                                            and(&), dollar($), percentage(%), star(*), dot(.), caret(^), single
                                            quotation("), slash(/) and comma(,).
                                        </kendo-formerror>
                                        <div class="k-form-error" *ngIf="duplicate_name">
                                            Subdomain name is already taken.
                                        </div>
                                    </kendo-formfield>
                                </div>
                                <div class="col-12 col-xl-12 col-lg-12 col-md-12">
                                    <kendo-formfield>
                                        <label>Description (for internal use only)</label>
                                        <textarea formControlName="description" #description maxlength="2000"
                                            kendoTextArea></textarea>
                                        <kendo-formerror *ngIf="subdomainForm.controls.description.errors?.pattern">
                                            Please enter valid description. Allowed special characters are dash(-),
                                            underscore(_), round brackets, at the rate(&#64;), exclamation(!), hash(#),
                                            and(&), dollar($), percentage(%), star(*), dot(.), caret(^), single
                                            quotation("), slash(/) and comma(,).
                                        </kendo-formerror>
                                    </kendo-formfield>
                                </div>
                                <div class="col-12 col-xl-12 col-lg-12 col-md-12"
                                    *ngIf="globals.currentModule == 'dcm'">
                                    <kendo-formfield>
                                        <label>Emerging - Description</label>
                                        <kendo-editor formControlName="emerging_desc">
                                            <kendo-toolbar>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorItalicButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorUnderlineButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorStrikethroughButton>
                                                    </kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorAlignLeftButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorAlignCenterButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorAlignRightButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorAlignJustifyButton>
                                                    </kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorInsertUnorderedListButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorInsertOrderedListButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorIndentButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorOutdentButton>
                                                    </kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorCreateLinkButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorUnlinkButton>
                                                    </kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                                                <kendo-toolbar-button kendoEditorViewSourceButton>
                                                </kendo-toolbar-button>
                                            </kendo-toolbar>
                                        </kendo-editor>
                                    </kendo-formfield>
                                </div>
                                <div class="col-12 col-xl-12 col-lg-12 col-md-12"
                                    *ngIf="globals.currentModule == 'dcm'">
                                    <kendo-formfield>
                                        <label>Established - Description</label>
                                        <kendo-editor formControlName="established_desc">
                                            <kendo-toolbar>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorItalicButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorUnderlineButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorStrikethroughButton>
                                                    </kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorAlignLeftButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorAlignCenterButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorAlignRightButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorAlignJustifyButton>
                                                    </kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorInsertUnorderedListButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorInsertOrderedListButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorIndentButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorOutdentButton>
                                                    </kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorCreateLinkButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorUnlinkButton>
                                                    </kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                                                <kendo-toolbar-button kendoEditorViewSourceButton>
                                                </kendo-toolbar-button>
                                            </kendo-toolbar>
                                        </kendo-editor>
                                    </kendo-formfield>
                                </div>
                                <div class="col-12 col-xl-12 col-lg-12 col-md-12"
                                    *ngIf="globals.currentModule == 'dcm'">
                                    <kendo-formfield>
                                        <label>Accomplished - Description</label>
                                        <kendo-editor formControlName="expert_desc">
                                            <kendo-toolbar>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorUndoButton></kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorRedoButton></kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorBoldButton></kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorItalicButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorUnderlineButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorStrikethroughButton>
                                                    </kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorAlignLeftButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorAlignCenterButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorAlignRightButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorAlignJustifyButton>
                                                    </kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorInsertUnorderedListButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorInsertOrderedListButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorIndentButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorOutdentButton>
                                                    </kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-toolbar-buttongroup>
                                                    <kendo-toolbar-button kendoEditorCreateLinkButton>
                                                    </kendo-toolbar-button>
                                                    <kendo-toolbar-button kendoEditorUnlinkButton>
                                                    </kendo-toolbar-button>
                                                </kendo-toolbar-buttongroup>
                                                <kendo-editor-insert-table-button></kendo-editor-insert-table-button>
                                                <kendo-toolbar-button kendoEditorViewSourceButton>
                                                </kendo-toolbar-button>
                                            </kendo-toolbar>
                                        </kendo-editor>
                                    </kendo-formfield>
                                </div>
                                <div class="col-12 col-xl-6 col-lg-6 col-md-6">
                                    <kendo-formfield>
                                        <label><span>*</span> Is Active?</label>
                                        <kendo-switch formControlName="is_active" #is_active [onLabel]="'Yes'"
                                            [offLabel]="'No'">
                                        </kendo-switch>
                                    </kendo-formfield>
                                </div>
                            </div>
                        </div>
                        <div class="text-center">
                            <button kendoButton (click)="addUpdate()" [primary]="true" class="all_btn theme_btn">
                                <ng-template [ngIf]="id==0" [ngIfElse]="updateButton"> Add </ng-template>
                                <ng-template #updateButton> Update </ng-template>
                            </button>
                            <button type="button" kendoButton
                                routerLink="/{{this.globals.currentLink.split('/')[1]}}/configuration/subdomain/list"
                                [primary]="true" class="all_btn themeoption_btn"
                                *ngIf="globals.permissions['subdomain-list']">
                                Cancel
                            </button>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    </div>
</div>