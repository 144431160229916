import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { Globals } from '../../globals';
import { Router, ActivatedRoute } from '@angular/router';
import { QuestionService } from '../../services/question.service';
import { DomainService } from '../../services/domain.service';
import { SubdomainService } from '../../services/subdomain.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { ConfigurationService } from '../../services/configuration.service';
declare var Swal: any;

@Component({
    selector: 'app-item',
    templateUrl: './item.component.html'
})
export class ItemComponent implements OnInit {

    id;
    questionForm: UntypedFormGroup;
    domainList = [];
    domainListFilterData: any = [];
    subdomainList = [{ id: '', name: 'Select a Domain First' }];
    subdomainListFilterData: any = [];
    levelList = [];
    levelListFilterData: any = [];

    defaultItemText = { lowercase: 'item', titlecase: 'Item', titlecase_plural: 'Items', titlecase_with_article: 'an Item' };
    dcmItemText = { lowercase: 'knowledge statements', titlecase: 'Knowledge Statement', titlecase_plural: 'Knowledge Statements', titlecase_with_article: 'a Knowledge Statement' };
    itemText = this.defaultItemText;

    constructor(public globals: Globals, private router: Router, private route: ActivatedRoute,
        private QuestionService: QuestionService, private DomainService: DomainService, private SubdomainService: SubdomainService,
        private formBuilder: UntypedFormBuilder, private ConfigurationService: ConfigurationService) { }

    ngOnInit(): void {

        this.itemText = (this.globals.currentModule == 'dcm') ? this.dcmItemText : this.defaultItemText;

        let self = this;
        setTimeout(function () {
            self.globals.breadcrumbs = [self.route.snapshot.data['product'], 'Manage', this.itemText.titlecase_plural];
        }, 100);

        this.questionForm = this.formBuilder.group({
            question_provider_id: [''],
            question_text: ['', [Validators.required,noWhitespaceValidator()]],
            domain_id: ['', [Validators.required]],
            subdomain_id: ['', [Validators.required]],
            question_type: [''],
            description: [''],
            is_active: [1]
        });


        this.globals.isLoading = false;

        this.id = (this.route.snapshot.paramMap.get('id')) ? this.route.snapshot.paramMap.get('id') : 0;


        if (this.globals.currentModule == 'dcm') {
            this.questionForm.controls['question_type'].setValidators([Validators.required]);
            this.questionForm.controls['question_type'].updateValueAndValidity();
            this.getLevel();
        }

        if (this.id != 0) {
            this.QuestionService.getById(this.id)
                .then((data) => {
                    var fields = data['data'];
                    this.questionForm.patchValue({
                        question_provider_id: fields['question_provider_id'],
                        question_text: fields['question_text'],
                        domain_id: fields['domain_id'],
                        subdomain_id: fields['subdomain_id'],
                        question_type: fields['question_type'],
                        description: fields['description'],
                        is_active: fields['is_active']
                    });
                    this.SubdomainService.getByDomain([fields['domain_id']])
                        .then((data) => {
                            var subdomainList: any;
                            subdomainList = data['data'];
                            this.subdomainList = [];
                            var defaultState = (subdomainList.length > 0) ? { id: '', name: 'Select a Subdomain' } : { id: '', name: 'No Subdomain Found!' };
                            this.subdomainList.push(defaultState);
                            this.subdomainList = [...this.subdomainList, ...subdomainList];
                            this.subdomainListFilterData = this.subdomainList;
                            this.questionForm.patchValue({
                                subdomain_id: fields['subdomain_id'],
                            });
                        },
                            (error) => {
                                this.globals.isLoading = false;
                                this.globals.errorSweetAlert();
                            });
                    this.globals.isLoading = false;
                },
                    (error) => {
                        this.globals.isLoading = false;
                        this.globals.errorSweetAlert();
                    });
        }

        this.getDomains();
    }

    getLevel() {
        this.ConfigurationService.getAll({ "key": ["LevelType"], "status": null })
            .then((data) => {
                var levelList: any;
                levelList = data['data']['LevelType'];
                var defaultItem = {
                    id: '',
                    display_text: 'Select a Level'
                }
                this.levelList.push(defaultItem);
                this.levelList = [...this.levelList, ...levelList];
                this.levelListFilterData = this.levelList;
            },
                (error) => {
                    this.globals.isLoading = false;
                    this.globals.errorSweetAlert();
                });
    }

    getDomains() {
        this.DomainService.getActive((this.id != 0) ? 2 : 1)
            .then((data) => {
                var domainList: any;
                domainList = data['data'].filter((s) => s.is_active == 1 || s.id == this.questionForm.value.domain_id);
                // domainList = data['data'];
                var defaultState = {
                    id: '',
                    name: 'Select a Domain'
                }
                this.domainList.push(defaultState);
                this.domainList = [...this.domainList, ...domainList];
                this.domainListFilterData = this.domainList;
            },
                (error) => {
                    this.globals.isLoading = false;
                    this.globals.errorSweetAlert();
                });
    }

    getSubdomainsByDomian(id) {
        if (id != null && id != '') {
            this.questionForm.patchValue({
                subdomain_id: '',
            });
            this.SubdomainService.getByDomain([id], (this.id != 0) ? 2 : 1)
                .then((data) => {
                    var subdomainList: any;
                    subdomainList = data['data'].filter((s) => s.is_active == 1 || s.id == this.questionForm.value.subdomain_id);
                    // subdomainList = data['data'];
                    this.subdomainList = [];
                    var defaultState = (subdomainList.length > 0) ? { id: '', name: 'Select a Subdomain' } : { id: '', name: 'No Subdomain Found!' };
                    this.subdomainList.push(defaultState);
                    this.subdomainList = [...this.subdomainList, ...subdomainList];
                    this.subdomainListFilterData = this.subdomainList;
                },
                    (error) => {
                        this.globals.isLoading = false;
                        this.globals.errorSweetAlert();
                    });
        } else {
            this.subdomainList = [{ id: '', name: 'Select a Domain First' }];
        }
    }

    addUpdate() {
        this.questionForm.markAllAsTouched();
        if (this.questionForm.valid) {
            this.questionForm.value.is_active =
                (this.questionForm.value.is_active == true || this.questionForm.value.is_active == 1)
                    ? 1
                    : 0;
            this.globals.isLoading = true;
            if (this.id == 0) {
                this.add(this.questionForm.value);
            } else {
                this.update(this.questionForm.value, this.id);
            }
        }
    }

    add(entity) {
        this.QuestionService.add(entity)
            .then((data) => {
                this.globals.isLoading = false;
                this.globals.sweetAlert('success', this.itemText.titlecase + ' Added', this.itemText.titlecase + ' has been added successfully.');
                this.router.navigate(['/' + this.globals.currentLink.split('/')[1] + '/configuration/' + 'knowledge-statement' + '/list']);
            },
                (error) => {
                    this.globals.isLoading = false;
                    this.globals.errorSweetAlert();
                });
    }

    update(entity, id) {
        this.QuestionService.update(entity, id)
            .then((data) => {
                this.globals.isLoading = false;
                this.globals.sweetAlert('success', this.itemText.titlecase + ' Updated', this.itemText.titlecase + ' has been updated successfully.');
                this.router.navigate(['/' + this.globals.currentLink.split('/')[1] + '/configuration/' + 'knowledge-statement' + '/list']);
            },
                (error) => {
                    this.globals.isLoading = false;
                    if (error.status = 403) {
                        this.globals.sweetAlert('warning', 'Oops...', "You can't de-active this " + this.itemText.lowercase + " because minimum active " + this.itemText.lowercase + " is required for this domain.");
                    } else {
                        this.globals.errorSweetAlert();
                    }
                });
    }

    domainFilter(value) {
        this.domainList = this.domainListFilterData.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }

    subdomainFilter(value) {
        this.subdomainList = this.subdomainListFilterData.filter((s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }

    levelFilter(value) {
        this.levelList = this.levelListFilterData.filter((s) => s.display_text.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }


}

export function noWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        console.log(control.value.replace(/<[^>]+>/g, ''))
      
        const value = control.value.replace(/<[^>]+>/g, '');
      const isWhitespace = (value || '').trim().length === 0;
    
      const isValid = !isWhitespace;
      return isValid ? null : { 'whitespace': 'value is only whitespace' };
    };
  }

