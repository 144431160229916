import { Component, OnInit, ViewChild } from '@angular/core';
import { Globals } from '../../globals';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import {
  DataBindingDirective,
  PageChangeEvent,
} from '@progress/kendo-angular-grid';

import { DomainService } from '../../services/domain.service';
import { SubdomainService } from '../../services/subdomain.service';
import { QuestionService } from '../../services/question.service';
import { ConfigurationService } from '../../services/configuration.service';

declare var Swal, $: any;

@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
})
export class ItemListComponent implements OnInit {
  itemList;

  pageSize: number = 10;
  allowUnsort = true;
  skip: number = 0;

  paginationEntity = {
    domain_id: [],
    subdomain_id: [],
    question_type: [],
    limit: this.pageSize,
    offset: 0,
    searchData: {
      status: '',
      searchQuery: '',
    },
    sortOrder: [
      {
        field: 'question_provider_id',
        dir: 'asc',
      },
    ],
  };
  state = {
    skip: 0,
    take: this.pageSize,
  };
  sort: SortDescriptor[] = [
    {
      field: 'question_provider_id',
      dir: 'asc',
    },
  ];

  domainList = [];
  domainListFilterData: any = [];
  subdomainList = [];
  subdomainListFilterData: any = [];
  levelList = [];
  levelListFilterData: any = [];
  searchFilterResult = {
    domain: [],
    subdomain: [],
    level: [],
  };
  filterForm: UntypedFormGroup;

  defaultItemText = {
    lowercase: 'item',
    titlecase: 'Item',
    titlecase_plural: 'Items',
    titlecase_with_article: 'an Item',
  };
  dcmItemText = {
    lowercase: 'knowledge statement',
    titlecase: 'Knowledge Statement',
    titlecase_plural: 'Knowledge Statements',
    titlecase_with_article: 'a Knowledge Statement',
  };
  itemText = this.defaultItemText;

  public itemDisabled(itemArgs: { dataItem: any; index: number }): boolean {
    return itemArgs.dataItem.total_task == 0;
  }

  constructor(
    public globals: Globals,
    private router: Router,
    private route: ActivatedRoute,
    private DomainService: DomainService,
    private SubdomainService: SubdomainService,
    private QuestionService: QuestionService,
    private ConfigurationService: ConfigurationService,
    private formBuilder: UntypedFormBuilder
  ) {}

  @ViewChild(DataBindingDirective, { static: false })
  dataBinding: DataBindingDirective;

  ngOnInit(): void {
    this.itemText =
      this.globals.currentModule == 'dcm'
        ? this.dcmItemText
        : this.defaultItemText;

    let self = this;
    setTimeout(function () {
      self.globals.breadcrumbs = [
        self.route.snapshot.data['product'],
        'Manage',
        this.itemText.titlecase_plural,
      ];
    }, 100);

    this.filterForm = this.formBuilder.group({
      domain_id: [[]],
      subdomain_id: [[]],
      level_id: [[]],
    });
    this.getQuestionData();
    this.getDomain();
    if (this.globals.currentModule == 'dcm') {
      this.getLevel();
    }
  }

  getQuestionData() {
    this.globals.isLoading = true;
    this.paginationEntity.domain_id = this.filterForm.value.domain_id;
    this.paginationEntity.subdomain_id = this.filterForm.value.subdomain_id;
    this.paginationEntity.question_type = this.filterForm.value.level_id;
    this.QuestionService.getAll(this.paginationEntity).then(
      (data: any) => {
        this.itemList = {
          data: data.count > 0 ? orderBy(data.data, this.sort) : '',
          total: data.count,
        };
        this.globals.isLoading = false;
        this.skip = this.skip > this.itemList.total ? 0 : this.skip;
      },
      (error) => {
        this.globals.isLoading = false;
        this.globals.errorSweetAlert();
      }
    );
  }

  getLevel() {
    this.ConfigurationService.getAll({ key: ['LevelType'], status: null }).then(
      (data) => {
        this.levelList = data['data']['LevelType'];
        this.levelListFilterData = data['data']['LevelType'];
      },
      (error) => {
        this.globals.isLoading = false;
        this.globals.errorSweetAlert();
      }
    );
  }

  getDomain() {
    this.DomainService.getActive(2).then(
      (data) => {
        this.domainList = data['data'];
        this.domainListFilterData = data['data'];
      },
      (error) => {
        this.globals.isLoading = false;
        this.globals.errorSweetAlert();
      }
    );
  }

  getSubdomainsByDomian(id) {
    if (id != null && id != '') {
      this.SubdomainService.getByDomain(id).then(
        (data) => {
          var subdomainList: any;
          subdomainList = data['data'];
          this.subdomainList = [];
          this.subdomainList = subdomainList;
          this.subdomainListFilterData = this.subdomainList;
        },
        (error) => {
          this.globals.isLoading = false;
          this.globals.errorSweetAlert();
        }
      );
    } else {
      this.subdomainList = [];
    }
  }

  filterData() {
    if (this.filterForm.value.domain_id.length > 0) {
      this.searchFilterResult.domain = this.filterObjectArray(
        this.domainListFilterData,
        this.filterForm.value.domain_id
      );
    } else {
      this.searchFilterResult.domain = [];
    }
    if (this.filterForm.value.subdomain_id.length > 0) {
      this.searchFilterResult.subdomain = this.filterObjectArray(
        this.subdomainListFilterData,
        this.filterForm.value.subdomain_id
      );
    } else {
      this.searchFilterResult.subdomain = [];
    }
    if (this.filterForm.value.level_id.length > 0) {
      this.searchFilterResult.level = this.filterObjectArray(
        this.levelListFilterData,
        this.filterForm.value.level_id
      );
    } else {
      this.searchFilterResult.level = [];
    }

    this.pageSize = 10;
    this.skip = 0;
    this.paginationEntity.limit = this.pageSize;
    this.paginationEntity.offset = 0;
    this.state = {
      skip: 0,
      take: this.pageSize,
    };

    this.getQuestionData();
    $('.advanced_search_block').toggleClass('active');
    $('.search_main_block').toggleClass('right_slide');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  clearFilterElement(field, id = 'all') {
    $('.advanced_search_block').toggleClass('active');
    $('.search_main_block').toggleClass('right_slide');
    this.filterForm.controls['domain_id'].patchValue(
      [].map.call(this.searchFilterResult.domain, (e) => e.id)
    );
    this.filterForm.controls['subdomain_id'].patchValue(
      [].map.call(this.searchFilterResult.subdomain, (e) => e.id)
    );
    this.filterForm.controls['level_id'].patchValue(
      [].map.call(this.searchFilterResult.level, (e) => e.id)
    );
    switch (field) {
      case 'domain':
        if (id == 'all') {
          this.filterForm.controls['domain_id'].patchValue([]);
        } else {
          var domains = this.filterForm.controls['domain_id'].value;
          domains.splice(domains.indexOf(id), 1);
          this.filterForm.controls['domain_id'].patchValue(domains);
        }
        this.filterData();
        break;
      case 'subdomain':
        if (id == 'all') {
          this.filterForm.controls['subdomain_id'].patchValue([]);
        } else {
          var subdomains = this.filterForm.controls['subdomain_id'].value;
          subdomains.splice(subdomains.indexOf(id), 1);
          this.filterForm.controls['subdomain_id'].patchValue(subdomains);
        }
        this.filterData();
        break;
      case 'level':
        if (id == 'all') {
          this.filterForm.controls['level_id'].patchValue([]);
        } else {
          var levels = this.filterForm.controls['level_id'].value;
          levels.splice(levels.indexOf(id), 1);
          this.filterForm.controls['level_id'].patchValue(levels);
        }
        this.filterData();
        break;
    }
  }

  updateStatus(dataItem, index) {
    let text;
    let ctext;
    if (this.itemList.data[index].is_active == true) {
      text = 'activate';
      ctext = 'Activate';
    } else {
      text = 'deactivate';
      ctext = 'Deactivate';
    }
    Swal.fire({
      icon: 'warning',
      title: ctext + ' ' + this.itemText.titlecase + ' - ' + dataItem.domain,
      text:
        'Are you sure, you want to ' +
        text +
        ' this ' +
        this.itemText.lowercase +
        '?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        this.globals.isLoading = true;
        this.QuestionService.updateStatus(dataItem.id).then(
          (data) => {
            this.globals.isLoading = false;
            this.globals.sweetAlert(
              'success',
              this.itemText.titlecase + ' ' + ctext + 'd',
              this.itemText.titlecase + ' ' + text + 'd successfully.'
            );
          },
          (error) => {
            this.globals.isLoading = false;
            if ((error.status = 403)) {
              $('#test' + index).removeClass('k-switch-off');
              $('#test' + index).addClass('k-switch-on');
              this.itemList.data[index].is_active = 1;
              this.globals.sweetAlert(
                'warning',
                'Oops...',
                "You can't deactive this " +
                  this.itemText.lowercase +
                  ' because minimun active ' +
                  this.itemText.lowercase +
                  ' is required for this domain.'
              );
            } else {
              this.globals.errorSweetAlert();
            }
          }
        );
      } else {
        this.itemList.data[index].is_active =
          !this.itemList.data[index].is_active;
      }
    });
  }

  delete(dataItem) {
    Swal.fire({
      icon: 'warning',
      title: 'Delete ' + this.itemText.titlecase + ' - ' + dataItem.domain,
      text:
        'Are you sure, you want to delete this ' +
        this.itemText.lowercase +
        '?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        this.globals.isLoading = true;
        this.QuestionService.delete(dataItem.id).then(
          (data) => {
            this.globals.isLoading = false;
            this.getQuestionData();
            this.globals.sweetAlert(
              'success',
              this.itemText.titlecase + ' Deleted',
              this.itemText.titlecase + ' has been deleted successfully.'
            );
          },
          (error) => {
            this.globals.isLoading = false;
            if ((error.status = 403)) {
              this.globals.sweetAlert(
                'warning',
                'Oops...',
                "You can't de-active this " +
                  this.itemText.lowercase +
                  ' because minimun active ' +
                  this.itemText.lowercase +
                  ' is required for this domain.'
              );
            } else {
              this.globals.errorSweetAlert();
            }
          }
        );
      }
    });
  }

  edit(id) {
    this.router.navigate([
      '/' +
        this.globals.currentLink.split('/')[1] +
        '/configuration/' +
        'knowledge-statement' +
        '/edit/' +
        id,
    ]);
  }

  selectAll(field) {
    let self = this;
    switch (field) {
      case 'domain':
        setTimeout(function () {
          self.filterForm.controls['domain_id'].patchValue(
            $.map(self.domainListFilterData, function (dataItem) {
              return dataItem.id;
            })
          );
          self.getSubdomainsByDomian(self.filterForm.value.domain_id);
        }, 100);
        break;
      case 'subdomain':
        setTimeout(function () {
          self.filterForm.controls['subdomain_id'].patchValue(
            $.map(self.subdomainListFilterData, function (dataItem) {
              return dataItem.id;
            })
          );
        }, 100);
        break;
      case 'level':
        setTimeout(function () {
          self.filterForm.controls['level_id'].patchValue(
            $.map(self.levelListFilterData, function (dataItem) {
              return dataItem.id;
            })
          );
        }, 100);
        break;
    }
  }

  clearAll(field) {
    let self = this;
    switch (field) {
      case 'domain':
        setTimeout(function () {
          self.filterForm.controls['domain_id'].patchValue([]);
          self.subdomainList = [];
          self.filterForm.controls['subdomain_id'].patchValue([]);
        }, 100);
        break;
      case 'subdomain':
        setTimeout(function () {
          self.filterForm.controls['subdomain_id'].patchValue([]);
        }, 100);
        break;
      //   case 'level':
      //     setTimeout(function () {
      //       self.filterForm.controls['level_id'].patchValue([]);
      //     }, 100);
      //     break;
    }
  }

  resetFilter() {
    this.filterForm = this.formBuilder.group({
      domain_id: [[]],
      subdomain_id: [[]],
      level_id: [[]],
    });
    this.clearFilterElement('domain');
    this.clearFilterElement('subdomain');
    this.clearFilterElement('level');
    this.advanced_search_block()
    //this.getQuestionData();
    // $(".advanced_search_block").toggleClass("active");
    // $(".search_main_block").toggleClass("right_slide");
    // window.scrollTo({top: 0, behavior: 'smooth'});
  }

  // pageChange Event
  public pageChange(event: PageChangeEvent): void {
    this.skip =
      this.pageSize == event.take
        ? event.skip
        : this.skip < event.take
        ? event.skip
        : this.skip;
    this.pageSize = event.take;
    this.paginationEntity.offset = Math.floor(this.skip / this.pageSize);
    this.paginationEntity.limit = event.take;
    this.getQuestionData();
  }

  // sortOrder change Event
  public sortChange(sort: any): void {
    if (sort.dir != 'undefined') {
      this.sort = sort;
      this.paginationEntity.sortOrder = [];
      this.paginationEntity.sortOrder = sort;
      this.getQuestionData();
    }
  }

  // Filter event
  public onFilter(inputValue: string): void {
    this.globals.isLoading = true;
    if (inputValue != '' && inputValue.length > 2) {
      this.paginationEntity.offset = 0;
      this.paginationEntity.searchData.searchQuery = inputValue;
      this.getQuestionData();
    } else if (inputValue == '') {
      this.paginationEntity.searchData.searchQuery = '';
      this.pageChange(this.state);
    }
  }

  advanced_search_block() {
    $('.advanced_search_block').toggleClass('active');
    $('.search_main_block').toggleClass('right_slide');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  domainFilter(value) {
    this.domainList = this.domainListFilterData.filter(
      (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public isDomainSelected(id) {
    return this.filterForm.value.domain_id.some((item) => item === id);
  }

  subdomainFilter(value) {
    this.subdomainList = this.subdomainListFilterData.filter(
      (s) => s.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public isSubDomainSelected(id) {
    return this.filterForm.value.subdomain_id.some((item) => item === id);
  }

  levelFilter(value) {
    this.levelList = this.levelListFilterData.filter(
      (s) => s.display_text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public isLevelSelected(id) {
    return this.filterForm.value.level_id.some((item) => item === id);
  }

  filterObjectArray(data, filterData) {
    return data.reduce((a, b) => {
      return filterData.includes(b.id) ? [...a, b] : a;
    }, []);
  }
}
