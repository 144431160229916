import { Component, OnInit, ViewChild } from '@angular/core';
import { DataBindingDirective, PageChangeEvent } from '@progress/kendo-angular-grid';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { Globals } from '../globals';
declare var Swal, $: any;

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html'
})
export class NotFoundComponent implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder, public globals: Globals) { }
  


  ngOnInit(): void {

  }
}
