import { Injectable } from '@angular/core';
import { Globals } from '../globals';
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class DomainService {

    constructor(private http: HttpClient, private globals: Globals) { }

    add(entity) {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.globals.baseAPIUrl + '/domain/add', entity)
                .toPromise()
                .then(
                    res => { // Success
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    update(entity, id) {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.globals.baseAPIUrl + '/domain/edit/' + id, entity)
                .toPromise()
                .then(
                    res => { // Success
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    getById(id) {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.globals.baseAPIUrl + '/domain/edit/' + id)
                .toPromise()
                .then(
                    res => { // Success
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    getAll(entity) {
        let promise = new Promise((resolve, reject) => {
            this.http.post(this.globals.baseAPIUrl + '/domains', entity)
                .toPromise()
                .then(
                    res => { // Success
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    getActive(active = 1) {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.globals.baseAPIUrl + '/domain/get-domain/' + active)
                .toPromise()
                .then(
                    res => { // Success
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    updateStatus(id) {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.globals.baseAPIUrl + '/domain/update-status/' + id)
                .toPromise()
                .then(
                    res => { // Success
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }

    delete(id) {
        let promise = new Promise((resolve, reject) => {
            this.http.get(this.globals.baseAPIUrl + '/domain/delete/' + id)
                .toPromise()
                .then(
                    res => { // Success
                        resolve(res);
                    },
                    msg => { // Error
                        reject(msg);
                    }
                );
        });
        return promise;
    }
}

