import { Component, OnInit } from '@angular/core';
import { Globals } from '../../globals';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfigurationService } from '../../services/configuration.service';
declare var Swal: any;


@Component({
    selector: 'app-item-per-subdomain',
    templateUrl: './item-per-subdomain.component.html'
})
export class ItemPerSubdomainComponent implements OnInit {

    entity;
    submitted;
    domainList;
    NoOfTotalAssessmentQuestions = 0;
    NoOfMinimumAssessmentQuestions = 0;
    total_active_questions: number = 0;
    total_questions_for_assessment: number = 0;
    mandatory_questions_for_assessment: number = 0;
    total_questions: number = 0;

    constructor(public globals: Globals, private router: Router, private route: ActivatedRoute, private ConfigurationService: ConfigurationService) { }


    ngOnInit(): void {
        let self = this;
        setTimeout(function () {
            self.globals.breadcrumbs = [self.route.snapshot.data['product'], 'Configuration', 'Assessment Items per Subdomain'];
        }, 100);
        this.getConfigData();
        this.getSubdomainData();
    }

    getConfigData() {
        this.globals.isLoading = true;
        this.ConfigurationService.getAll({ "key": ["NoOfTotalAssessmentQuestions", "MinimunAttemptQuestions"], "status": null })
            .then((data) => {
                this.NoOfTotalAssessmentQuestions = data['data']['NoOfTotalAssessmentQuestions'][0]['display_text'];
                this.NoOfMinimumAssessmentQuestions = data['data']['MinimunAttemptQuestions'][0]['display_text'];
                this.globals.isLoading = false;
            },
                (error) => {
                    this.globals.isLoading = false;
                    this.globals.errorSweetAlert();
                });
    }

    getSubdomainData() {
        this.globals.isLoading = true;
        this.ConfigurationService.getSubdomainData()
            .then((data) => {
                this.domainList = data['data'];
                this.globals.isLoading = false;
                this.countTotalItemsForAssessment();
            },
                (error) => {
                    this.globals.isLoading = false;
                    this.globals.errorSweetAlert();
                });
    }

    checkSum(value, index) {
        this.domainList[index].attempt_questions = (this.domainList[index].attempt_questions > 0 && this.domainList[index].attempt_questions > value) ? null : this.domainList[index].attempt_questions;
        this.countTotalItemsForAssessment();
    }

    checkSumMandatory(value, index) {
        this.countTotalItemsForAssessment();
    }

    countTotalItemsForAssessment() {
        this.total_questions = 0;
        this.total_active_questions = 0;
        this.total_questions_for_assessment = 0;
        this.mandatory_questions_for_assessment = 0;
        for (let subdomain of this.domainList) {
            this.total_questions += subdomain.total_question;
            this.total_active_questions += subdomain.total_active_question;
            this.total_questions_for_assessment += subdomain.number_of_questions;
            this.mandatory_questions_for_assessment += subdomain.attempt_questions;
        }
    }

    update() {
        if (!this.checkConditions()) {
            this.globals.isLoading = true;
            this.ConfigurationService.updateSubdomainData(this.domainList)
                .then((data) => {
                    this.globals.isLoading = false;
                    this.submitted = false;
                    this.globals.sweetAlert('success', 'Configuration Update', 'Configuration has been updated successfully.');
                },
                    (error) => {
                        this.globals.isLoading = false;
                        this.globals.errorSweetAlert();
                    });
        }
    }

    checkConditions() {
        var error = false;
        if (this.total_questions_for_assessment != this.NoOfTotalAssessmentQuestions || this.mandatory_questions_for_assessment > this.total_questions_for_assessment || this.mandatory_questions_for_assessment < this.NoOfMinimumAssessmentQuestions) {
            error = true;
        };
        for (let subdomain of this.domainList) {
            if (subdomain.number_of_questions > subdomain.total_active_question || subdomain.attempt_questions > subdomain.number_of_questions || subdomain.attempt_questions < 0 || subdomain.number_of_questions < 0) { error = true; };
        }
        return error;
    }

}