<div class="inner_content_block">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-xl-12 col-lg-12 col-md-12">
                <div class="title_block">
                    <h1 class="float-start">Subdomains</h1>
                    <a kendoButton routerLink="/{{this.globals.currentLink.split('/')[1]}}/configuration/subdomain/add"
                        class="all_btn theme_btn float-end" *ngIf="globals.permissions['add-subdomain']">Add a
                        Subdomain</a>
                    <div class="clearfix"></div>
                </div>
                <div class="advanced_search_block">
                    <form class="k-form" [formGroup]="filterForm">
                        <fieldset class="k-form-fieldset">
                            <h5 class="float-start">Advanced Search</h5>
                            <button class="float-end close close_search" type="button"
                                (click)="advanced_search_block()"><span>×</span></button>
                            <div class="clearfix"></div>

                            <kendo-formfield>
                                <label class="float-start">Domain</label>
                                <button class="select_btn float-end" (click)="selectAll('domain')"
                                    *ngIf="filterForm.value.domain_id.length != domainListFilterData.length">Select
                                    all</button>
                                <button class="select_btn float-end" (click)="clearAll('domain')"
                                    *ngIf="filterForm.value.domain_id.length == domainListFilterData.length">Clear
                                    all</button>
                                <kendo-multiselect [data]="domainList" #domain_id formControlName="domain_id"
                                    [textField]="'name'" [valueField]="'id'" [autoClose]="false" [filterable]="true"
                                    [valuePrimitive]="true" [itemDisabled]="itemDisabled"
                                    (filterChange)="domainFilter($event)">
                                    <ng-template kendoMultiSelectItemTemplate let-dataItem>
                                        <div class="check_box">
                                            <input type="checkbox" class="k-checkbox"
                                                [checked]="isDomainSelected(dataItem.id)">
                                            <label class="k-checkbox-label">{{dataItem.name}}&nbsp;({{dataItem.total_subdomain}})</label>
                                        </div>
                                    </ng-template>
                                    <ng-template kendoMultiSelectTagTemplate let-dataItem>
                                        {{ dataItem.name }}&nbsp;({{ dataItem.total_subdomain }})
                                    </ng-template>
                                    <ng-template kendoMultiSelectNoDataTemplate>
                                        <h6>NO DOMAIN FOUND</h6>
                                    </ng-template>
                                </kendo-multiselect>
                            </kendo-formfield>

                            <div class="text-center mb-4">
                                <button kendoButton type="submit" [primary]="true" class="all_btn theme_btn"
                                    (click)="filterData()">Search</button>
                                <button kendoButton [primary]="true" class="all_btn themeoption_btn"
                                    (click)="resetFilter()">
                                    Clear
                                </button>
                            </div>
                        </fieldset>
                    </form>
                </div>
                <div class="search_main_block">
                    <!-- <div class="filter_block">
                        <ul>
                            <li class="grey" *ngIf="globals.permissions['edit-subdomain']"><i class="fa fa-pencil"></i>Edit</li>
                            <li class="red" *ngIf="globals.permissions['delete-subdomain']"><i class="fa fa-trash"></i>Delete</li>
                        </ul>
                    </div> -->
                    <!-- <div class="clearfix"></div> -->
                    <kendo-grid [data]="subdomainList" [pageSize]="pageSize" [skip]="skip" [pageable]="true"
                        (pageChange)="pageChange($event)" [sort]="sort" [sortable]="{
                            allowUnsort: false,
                            mode: 'single'
                            }" (sortChange)="sortChange($event)">
                        <kendo-grid-messages pagerItems="Subdomains" pagerItemsPerPage="Subdomains per page"
                            noRecords="No subdomains available.">
                        </kendo-grid-messages>
                        <ng-template kendoGridToolbarTemplate>
                            <input placeholder="Search by domain, subdomain, or description" kendoTextBox
                                (input)="onFilter($event.target.value)" />
                            <button kendoButton kendoTooltip title="Advanced Search" position="top" class="theme_btn all_btn adv_btn" (click)="advanced_search_block()"><i
                                    class="fa fa-filter"></i></button>
                            <kendo-pager-page-sizes [pageSizes]="globals.pagesizes"></kendo-pager-page-sizes>
                            <div class="clearfix"></div>
                            <!-- filter search criteria -->
                            <p class="search_result_text" *ngIf="searchFilterResult.domains.length > 0">
                                {{subdomainList.total}}
                                <span *ngIf="subdomainList.total > 1" class="result_title">Subdomains</span>
                                <span *ngIf="subdomainList.total <= 1" class="result_title">Subdomain</span> found for
                                <!-- domain -->
                                <span class="label_filter" kendoTooltip *ngIf="searchFilterResult.domains.length > 0">
                                    <b>Domain:</b>
                                    <span
                                        *ngIf="searchFilterResult.domains.length <= globals.maximumSearchCriteriaDisplay && searchFilterResult.domains.length < domainListFilterData.length">
                                        <span class="inner_filter"
                                            *ngFor="let domain of searchFilterResult.domains;">{{domain.name}}<button
                                                title="Remove {{domain.name}}"
                                                (click)="clearFilterElement('domain',domain.id)"><i
                                                    class="fa fa-times"></i></button></span>
                                    </span>
                                    <span
                                        *ngIf="searchFilterResult.domains.length > globals.maximumSearchCriteriaDisplay && searchFilterResult.domains.length < domainListFilterData.length">{{searchFilterResult.domains.length}}
                                        Selected</span>
                                    <span *ngIf="searchFilterResult.domains.length == domainListFilterData.length">All
                                        Selected</span>
                                    <button title="Clear Domain" (click)="clearFilterElement('domain')"><i
                                            class="fa fa-times"></i></button>
                                </span>
                            </p>
                        </ng-template>
                        <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
                            <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
                            <kendo-pager-numeric-buttons [buttonCount]="globals.pageIndex">
                            </kendo-pager-numeric-buttons>
                            <kendo-pager-next-buttons></kendo-pager-next-buttons>
                            <kendo-pager-info></kendo-pager-info>
                        </ng-template>

                        <kendo-grid-column field="subdom_provider_id" title="ID"
                            [headerStyle]="{width: '5%','text-align': 'center'}" [style]="{width: '5%'}"
                            class="text-center" media="(min-width: 769px)">
                        </kendo-grid-column>
                        <kendo-grid-column field="domain_name" title="Domain" [headerStyle]="{width: '20%'}"
                            [style]="{width: '20%'}" media="(min-width: 769px)">
                        </kendo-grid-column>
                        <kendo-grid-column field="name" title="Subdomain" [headerStyle]="{width: '25%'}"
                            [style]="{width: '25%'}" media="(min-width: 769px)">
                        </kendo-grid-column>
                        <kendo-grid-column field="description" title="Description" [headerStyle]="{width: '30%'}"
                            [style]="{width: '30%'}" media="(min-width: 769px)">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                {{ (dataItem.description.length>100)? (dataItem.description |
                                slice:0:100)+'...':(dataItem.description) }}
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="is_active" title="Is Active?"
                            [headerStyle]="{width: '10%','text-align': 'center'}" [style]="{width: '10%'}"
                            [sortable]="false" class="text-center" *ngIf="globals.permissions['edit-subdomain']"
                            media="(min-width: 769px)">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <!-- <kendo-switch (valueChange)="updateStatus(dataItem,rowIndex)" [(ngModel)]="dataItem.is_active"
                                    [checked]="dataItem.is_active==1" [onLabel]="'Yes'" [offLabel]="'No'">
                                </kendo-switch> -->
                                {{dataItem.is_active==1 ? 'Yes' : 'No'}}
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column title="Action" [style]="{width: '10%'}"
                            [headerStyle]="{width: '10%','text-align': 'center'}" media="(min-width: 769px)"
                            [sortable]="false" class="text-center"
                            *ngIf="globals.permissions['edit-subdomain'] || globals.permissions['delete-subdomain']">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div kendoTooltip>
                                    <button kendoButton (click)="edit(dataItem.id)" title="Edit"
                                        [iconClass]="'k-icon k-i-edit'" class="icon_btn grey"
                                        *ngIf="globals.permissions['edit-subdomain']"></button>
                                    <button kendoButton
                                        routerLink="/{{this.globals.currentLink.split('/')[1]}}/configuration/subdomain/interpretation/{{dataItem.id}}"
                                        title="Interpretation" [iconClass]="'k-icon k-i-grid-layout'"
                                        class="icon_btn blue"
                                        *ngIf="globals.currentModule == 'dcm' && globals.permissions['edit-subdomain']"></button>
                                    <button kendoButton (click)="delete(dataItem)" title="Delete"
                                        [iconClass]="'k-icon k-i-delete'" class="icon_btn red"
                                        *ngIf="globals.permissions['delete-subdomain']"></button>
                                </div>
                            </ng-template>
                        </kendo-grid-column>

                        <kendo-grid-column field="subdom_provider_id" title="ID"
                            [headerStyle]="{width: '60px','text-align': 'center'}" [style]="{width: '60px'}"
                            class="text-center" media="(max-width: 768px)">
                        </kendo-grid-column>
                        <kendo-grid-column field="domain_name" title="Domain" [headerStyle]="{width: '250px'}"
                            [style]="{width: '250px'}" media="(max-width: 768px)">
                        </kendo-grid-column>
                        <kendo-grid-column field="name" title="Subdomain" [headerStyle]="{width: '250px'}"
                            [style]="{width: '250px'}" media="(max-width: 768px)">
                        </kendo-grid-column>
                        <kendo-grid-column field="description" title="Description" [headerStyle]="{width: '300px'}"
                            [style]="{width: '300px'}" media="(max-width: 768px)">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                {{dataItem.description != "" ? dataItem.description : 'NA'}}
                                {{ (dataItem.description.length>100)? (dataItem.description |
                                slice:0:100)+'...':(dataItem.description) }}
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="is_active" title="Is Active?"
                            [headerStyle]="{width: '100px','text-align': 'center'}" [style]="{width: '100px'}"
                            [sortable]="false" class="text-center" *ngIf="globals.permissions['edit-subdomain']"
                            media="(max-width: 768px)">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <!-- <kendo-switch (valueChange)="updateStatus(dataItem,rowIndex)" [(ngModel)]="dataItem.is_active"
                                [checked]="dataItem.is_active==1" [onLabel]="'Yes'" [offLabel]="'No'">
                            </kendo-switch> -->
                                {{dataItem.is_active==1 ? 'Yes' : 'No'}}
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column title="Action" [style]="{width: '100px'}"
                            [headerStyle]="{width: '100px','text-align': 'center'}" media="(max-width: 768px)"
                            [sortable]="false" class="text-center"
                            *ngIf="globals.permissions['edit-subdomain'] || globals.permissions['delete-subdomain']">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div kendoTooltip>
                                    <button kendoButton (click)="edit(dataItem.id)" title="Edit"
                                        [iconClass]="'k-icon k-i-edit'" class="icon_btn grey"
                                        *ngIf="globals.permissions['edit-subdomain']"></button>
                                    <button kendoButton
                                        routerLink="/{{this.globals.currentLink.split('/')[1]}}/configuration/subdomain/interpretation/{{dataItem.id}}"
                                        title="Interpretation" [iconClass]="'k-icon k-i-grid-layout'"
                                        class="icon_btn blue"
                                        *ngIf="globals.currentModule == 'dcm' && globals.permissions['edit-subdomain']"></button>
                                    <button kendoButton (click)="delete(dataItem)" title="Delete"
                                        [iconClass]="'k-icon k-i-delete'" class="icon_btn red"
                                        *ngIf="globals.permissions['delete-subdomain']"></button>
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                    </kendo-grid>
                </div>
            </div>
        </div>
    </div>
</div>