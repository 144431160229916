import { Component, OnInit } from '@angular/core';
import { Globals } from '../../globals';
import { Router, ActivatedRoute } from '@angular/router';
import { DomainService } from '../../services/domain.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
declare var Swal: any;

@Component({
    selector: 'app-domain',
    templateUrl: './domain.component.html'
})
export class DomainComponent implements OnInit {

    id;
    domainForm: UntypedFormGroup;
    duplicate_name: boolean = false;

    constructor(public globals: Globals, private router: Router, private route: ActivatedRoute, private DomainService: DomainService, private formBuilder: UntypedFormBuilder) { }

    ngOnInit(): void {

        let self = this;
        setTimeout(function () {
            self.globals.breadcrumbs = [self.route.snapshot.data['product'], 'Manage', 'Domains'];
        }, 100);

        this.domainForm = this.formBuilder.group({
            dom_provider_id: [''],
            name: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9!@#$%^&*()-_.,/"]+[A-Za-z0-9!@#$%^&*()-_.,/" ]+$')]],
            description: ['', [Validators.pattern('^[A-Za-z0-9!@#$%^&*()-_.,/"]+[A-Za-z0-9!@#$%^&*()-_.,/" ]+$')]],
            is_active: [1]
        });
        this.globals.isLoading = false;

        this.id = (this.route.snapshot.paramMap.get('id')) ? this.route.snapshot.paramMap.get('id') : 0;

        if (this.id != 0) {
            this.DomainService.getById(this.id)
                .then((data) => {
                    var fields = data['data'];
                    this.domainForm.patchValue({
                        dom_provider_id: fields['dom_provider_id'],
                        name: fields['name'],
                        description: fields['description'],
                        is_active: fields['is_active']
                    });
                    this.globals.isLoading = false;
                },
                    (error) => {
                        this.globals.isLoading = false;
                        this.globals.errorSweetAlert();
                    });
        }
    }

    addUpdate() {
        this.domainForm.markAllAsTouched();
        if (this.domainForm.valid) {
            this.domainForm.value.is_active =
                (this.domainForm.value.is_active == true || this.domainForm.value.is_active == 1)
                    ? 1
                    : 0;
            this.globals.isLoading = true;
            if (this.id == 0) {
                this.add(this.domainForm.value);
            } else {
                this.update(this.domainForm.value, this.id);
            }
        }
    }

    add(entity) {
        this.DomainService.add(entity)
            .then((data) => {
                this.globals.isLoading = false;
                this.globals.sweetAlert('success', 'Domain Added', 'Domain has been added successfully.');
                this.router.navigate(['/' + this.globals.currentLink.split('/')[1] + '/configuration/domain/list']);
            },
                (error) => {
                    this.globals.isLoading = false;
                    if (error.error.code == 422) {
                        this.duplicate_name = (error.error.message.name != undefined) ? true : false;
                    } else {
                        this.globals.errorSweetAlert();
                    }
                });
    }

    update(entity, id) {
        this.DomainService.update(entity, id)
            .then((data) => {
                this.globals.isLoading = false;
                this.globals.sweetAlert('success', 'Domain Updated', 'Domain has been updated successfully.');
                this.router.navigate(['/' + this.globals.currentLink.split('/')[1] + '/configuration/domain/list']);
            },
                (error) => {
                    this.globals.isLoading = false;
                    if (error.error.code == 422) {
                        this.duplicate_name = (error.error.message.name != undefined) ? true : false;
                    } else {
                        this.globals.errorSweetAlert();
                    }
                });
    }
}