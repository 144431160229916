import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-common-app',
    templateUrl: './common-app.component.html'
})
export class CommonAppComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}